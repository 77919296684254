<template>
  <sui-segment :class="`segment-${role}`">
    <h2 v-if="isMain">
      {{ $t(`person.role.${role}`) }}
    </h2>

    <Tooltip v-if="isMain && !disabled" :text="$t('atLeastOneRequired')" />

    <sui-grid>
      <sui-grid-row>
        <sui-grid-column :width="16" :large-screen="2" :widescreen="2">
          <h3>{{ $t('person.info.data') }}</h3>
        </sui-grid-column>

        <sui-grid-column :width="14" :large-screen="7" :widescreen="7">
          <PersonInfo
            :data="personData"
            :disabled="representativeDisabled || disabled"
            :caseInfo="caseInfo"
            @search="handleSearch"
            @update="onUpdatePersonData"
          />
        </sui-grid-column>

        <sui-grid-column
          :width="16"
          :large-screen="2"
          :widescreen="2"
          class="contacts__title"
        >
          <h3>{{ $t('person.contacts.title') }}</h3>
        </sui-grid-column>

        <sui-grid-column :width="16" :large-screen="5" :widescreen="5">
          <PersonContacts
            :contacts="personData.contactsData"
            :disabled="representativeDisabled || disabled"
            @update="onUpdateContacts"
          />
        </sui-grid-column>
      </sui-grid-row>

      <PersonAddresses
        :addresses="personData.addressData"
        :disabled="disabled"
        @update="onUpdateAddresses"
      />
    </sui-grid>

    <PersonAccounts
      v-if="isMain && !(disabled && personData.accountsData.length === 0)"
      :accounts="personData.accountsData"
      :person="personData"
      :disabled="disabled"
      @update="onUpdateAccounts"
    />

    <PersonRepresentatives
      v-if="
        isMain && !(disabled && personData.representativesData.length === 0)
      "
      :disabled="disabled"
      :representatives="personData.representativesData"
      @update="onUpdateRepresentatives"
    />

    <div v-if="showButtons" class="button__container" style="margin-top: 1rem">
      <sui-divider />

      <span
        v-if="disabled"
        v-tooltip.top="{
          value: $t('case.fieldsDisabled', {
            state: $t(CaseStateToLabel[caseState]),
          }),
          disabled: isEditable,
        }"
      >
        <sui-button
          size="mini"
          :disabled="!isEditable"
          @click.prevent="$emit('edit')"
        >
          {{ $t('edit') }}
        </sui-button>
      </span>

      <sui-button v-if="!disabled" size="mini" @click.prevent="$emit('cancel')">
        {{ $t('cancel') }}
      </sui-button>

      <sui-button
        v-if="!disabled"
        :loading="isLoading"
        primary
        size="mini"
        type="submit"
      >
        {{ $t('save') }}
      </sui-button>
    </div>
  </sui-segment>
</template>
<script>
import api from '@/api';
import { CaseStateToLabel } from '@/static/enums/caseStateToLabel';
import { Genders, Roles, Types } from '@/static/enums/person';
import PersonAccounts from './PersonAccounts.vue';
import PersonAddresses from './PersonAddresses.vue';
import PersonContacts from './PersonContacts.vue';
import PersonInfo from './PersonInfo.vue';
import PersonRepresentatives from './PersonRepresentatives.vue';
import Tooltip from '../Tooltip.vue';
import { getFormattedName } from '@/mixins/person';
import { isCaseEditable } from '@/utils/isCaseEditable';

export default {
  name: 'Person',
  components: {
    PersonAccounts,
    PersonAddresses,
    PersonContacts,
    PersonInfo,
    PersonRepresentatives,
    Tooltip,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    role: {
      default: Roles.CLAIMANT,
      type: String,
      validator: (value) => Object.values(Roles).includes(value),
    },
    initialData: {
      type: Object,
      default: () => {},
    },
    representativeDisabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Current case object that was retrieved from v1 api,
     * see EnforcementCase.yml for serialized fields.
     */
    caseInfo: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      personData: {
        accountsData: [],
        addressData: [],
        contactsData: [],
        country: null,
        gender: Genders.MALE,
        representativesData: [],
        type: this.role === Roles.CLAIMANT ? Types.JURIDICAL : Types.PRIVATE,
      },
      isMain: this.role !== Roles.REPRESENTATIVE,
      CaseStateToLabel,
    };
  },
  computed: {
    showButtons() {
      if (!this.caseInfo) {
        return false;
      }

      return !this.caseInfo.in_transfer;
    },
    caseState() {
      return this.caseInfo.state;
    },
    /**
     * Whether the case can be edited (true) or not (false).
     */
    isEditable() {
      return isCaseEditable(this.caseInfo);
    },
  },
  created() {
    this.$watch(
      'initialData',
      (initialData) => {
        if (initialData && Object.keys(initialData).length > 0) {
          this.setNewPersonData(initialData);
        }
      },
      { immediate: true }
    );
  },
  methods: {
    getAddresses(ids) {
      if (ids && ids.length > 0) {
        api.addresses.getAddresses(ids).then((response) => {
          const newAddresses = response.data.items.filter(
            (newAddress) =>
              !this.personData.addressData.find(
                (address) => address.address === newAddress.address
              )
          );
          this.onUpdateAddresses([
            ...this.personData.addressData,
            ...newAddresses,
          ]);
        });
      }
    },
    getBankAccounts(ids) {
      if (ids && ids.length > 0) {
        api.banks.getAccounts(ids).then((response) => {
          const newBankAccounts = response.data
            .filter(
              (newBankAccount) =>
                !this.personData.accountsData.find(
                  (bankAccount) =>
                    bankAccount.accountNo === newBankAccount.accountNo
                )
            )
            .map((account) => {
              const newAccount = { ...account };
              const { name, person } = account;

              if (!name && person) {
                api.person.findById(person).then((res) => {
                  newAccount.name = getFormattedName(res.data);
                });
              }

              return newAccount;
            });

          this.onUpdateAccounts([
            ...this.personData.accountsData,
            ...newBankAccounts,
          ]);
        });
      }
    },
    getContacts(ids) {
      if (ids && ids.length > 0) {
        api.contacts.getContacts(ids).then((response) => {
          const newContacts = response.data.filter(
            (newContact) =>
              !this.personData.contactsData.find(
                (contact) => contact.value === newContact.value
              )
          );

          this.onUpdateContacts([
            ...this.personData.contactsData,
            ...newContacts,
          ]);
        });
      }
    },
    getRepresentatives(ids) {
      if (ids && ids.length > 0) {
        api.person.getPersonListByIds(ids).then((response) => {
          this.onUpdateRepresentatives(response.data);
        });
      }
    },
    setNewPersonData(data) {
      const { addresses, bankAccounts, contacts, representatives, ...rest } =
        data;

      this.personData = {
        ...this.personData,
        ...rest,
      };
      this.getAddresses(addresses);
      this.getBankAccounts(bankAccounts);
      this.getContacts(contacts);
      this.getRepresentatives(representatives);
    },
    onUpdatePersonData(data) {
      this.personData = { ...data };
      this.$emit('personDataChange', this.role, data);
    },
    onUpdateAccounts(accounts) {
      this.personData = {
        ...this.personData,
        accountsData: accounts,
      };
      this.$emit('personDataChange', this.role, this.personData);
    },
    onUpdateAddresses(addresses) {
      this.personData = {
        ...this.personData,
        addressData: addresses,
      };
      this.$emit('personDataChange', this.role, this.personData);
    },
    onUpdateContacts(contacts) {
      this.personData = {
        ...this.personData,
        contactsData: contacts,
      };
      this.$emit('personDataChange', this.role, this.personData);
    },
    onUpdateRepresentatives(representatives) {
      this.personData = {
        ...this.personData,
        representativesData: representatives,
      };
      this.$emit('personDataChange', this.role, this.personData);
    },
    handleSearch(id) {
      api.person.findForQuickSearch(id).then((person) => {
        this.setNewPersonData(person.data);
        this.$emit('personDataChange', this.role, this.personData);
      });
    },
  },
};
</script>
<style scoped>
.ui.segment {
  border: 0;
  box-shadow: none;
}

.ui.segment.segment-debtor {
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2), 0 -5px 0 0 rgba(139, 38, 53, 1),
    0 5px 0 0 rgba(139, 38, 53, 1);
  margin: 5px 0 calc(1rem + 5px);
}

.ui.segment.segment-claimant {
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2), 0 -5px 0 0 rgba(15, 82, 87, 1),
    0 5px 0 0 rgba(15, 82, 87, 1);
  margin: 5px 0 calc(1rem + 5px);
}

.ui.segment.segment-representative {
  padding-top: 0;
  padding-bottom: 0;
}

.button__container {
  text-align: right;
}

.info {
  font-style: italic;
}

.ui.button[data-tooltip] {
  position: absolute;
  right: 1rem;
  top: 1rem;
}

@media (min-width: 1200px) {
  .contacts__title {
    margin-left: -2rem;
  }
}
</style>
